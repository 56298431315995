<script setup lang="ts">
import {ref, reactive} from 'vue';
import {useStore} from 'vuex';
import {formatUrl} from '@teemill/common/helpers';
import {FormBuilder} from '@teemill/modules/form-builder';
import {snackbar, http} from '@teemill/common/services';
import {overlays} from '@teemill/modules/overlays';
import {TmlReCaptcha} from '@teemill/components';
import {Product} from '@teemill/common/models';
import {viewportSize} from '@teemill/common/helpers';
import {faImages} from '@fortawesome/pro-light-svg-icons';

const props = withDefaults(
  defineProps<{
    product: Product;
    maxAttachments: number;

    // used for controlling form field visibility
    customerHash?: string;
  }>(),
  {
    maxAttachments: 4,
  }
);

const options = ref([
  {text: '5 Stars', value: '5'},
  {text: '4 Stars', value: '4'},
  {text: '3 Stars', value: '3'},
  {text: '2 Stars', value: '2'},
  {text: '1 Star', value: '1'},
]);

const review = reactive<{
  rating?: string;
  name?: string;
  email?: string;
  review?: string;
  images?: File[];
}>({
  rating: undefined,
  name: undefined,
  email: undefined,
  review: undefined,
  images: undefined,
});

const store = useStore();

const submittingReview = ref(false);
const recaptchaElm = ref<InstanceType<typeof TmlReCaptcha>>();

const onSubmitReview = () => {
  if (submittingReview.value || !recaptchaElm.value?.recaptcha) {
    return;
  }

  if (store.getters['forms/product-review/isValid']) {
    submittingReview.value = true;
    recaptchaElm.value.recaptcha
      .ifHuman((recaptcha: any) => {
        http
          .post(formatUrl('/omnis/v3/frontend/product/submitProductReview/'), {
            productId: props.product.id,
            rating: review.rating,
            customerHash: props.customerHash,
            name: review.name,
            email: review.email,
            message: review.review,
            images: review.images,
            v3captchaToken: recaptcha.v3Token,
            v2captchaToken: recaptcha.v2Token,
          })
          .success(() => {
            snackbar('Thank you for your review');
            emit('submitted', {product: props.product, review});

            resetReview();
            overlays.close('share-review-popup');
          })
          .oops()
          .validation()
          .finally(() => {
            recaptchaElm.value?.recaptcha?.reload();
            submittingReview.value = false;
          });
      })
      .ifBot(() => {
        submittingReview.value = false;
      });
  } else {
    snackbar('Please fill out the review form');
  }
};

const onSet = <K extends keyof typeof review, V extends typeof review>({
  name,
  value,
}: {
  name: K;
  value: V[K];
}) => {
  review[name] = value;
};

const emit = defineEmits(['close', 'submitted']);

const onWrongFileType = () => {
  snackbar.error(
    'Sorry, we currently only support the upload of jpg, jpeg and png images.'
  );
};

const resetReview = () => {
  review.rating = undefined;
  review.name = undefined;
  review.email = undefined;
  review.review = undefined;
  review.images = undefined;
};

const onClose = () => {
  resetReview();

  emit('close');
};
</script>

<template>
  <tml-new-popup
    name="share-review-popup"
    title="Love it?"
    layout="standard"
    size="normal"
    height="auto"
    :fill="false"
    @close="onClose"
  >
    <template #content>
      <p class="mb-4 max-w-sm">
        Tell us how you feel about your {{ product.name }}.
      </p>

      <form-builder
        name="product-review"
        :value="review"
        :fields="{
          rating: {
            type: 'dropdown',
            options: options,
            rules: {required: true},
          },
          name: {
            type: 'text',
            label: 'Your name',
            rules: {required: true},
          },
          email: {
            when: !customerHash,
            type: 'text',
            label: 'Your email',
            rules: {required: true, email: true},
          },
          review: {
            type: 'textarea',
            rules: {required: true},
          },
          images: 'custom',
        }"
        @set="onSet"
      >
        <template #images>
          <tml-uploader
            v-model="review.images"
            :accept="['image/jpg', 'image/jpeg', 'image/png']"
            :multiple="false"
            :max-file-size="5000000"
            listen-for-global-paste-events
            @wrong-file-type="onWrongFileType"
          >
            <template #default="{fileBag, triggerUploader}">
              <div :class="{'px-4': fileBag.length}">
                <tml-sortable-list
                  :items="fileBag"
                  :max="maxAttachments"
                  :item-class-list="
                    fileBag.length
                      ? 'flex-1 mr-1'
                      : viewportSize.isSmaller('md')
                        ? 'col-6'
                        : 'col-3'
                  "
                  allow-delete
                >
                  <template #cell="props">
                    <tml-image
                      :src="props.item.src"
                      :alt="props.item.name"
                      :ratio="1"
                    />
                  </template>
                  <template #appendable>
                    <tml-placeholder
                      v-if="!review.images || review.images.length < maxAttachments"
                      :icon="faImages"
                      :aspect-ratio="1"
                      @click="triggerUploader"
                    />
                  </template>
                </tml-sortable-list>
              </div>
            </template>
          </tml-uploader>
        </template>
      </form-builder>
      <tml-re-captcha ref="recaptchaElm" action="subdomains/review" />
    </template>
    <template #footer>
      <tml-button
        class="mt-4"
        primary
        :loading="submittingReview"
        href="#"
        text="Submit Review"
        @click="onSubmitReview"
      />
    </template>
  </tml-new-popup>
</template>
